// Target the container and iframe
(function() {
  const chatContainer = document.getElementById("chat-container");

  // Extract the data-site-live URL
  const siteLiveUrl = chatContainer.getAttribute("data-site-live");
  if (!siteLiveUrl) {
    console.error("Service Assistant: data-site-live attribute is missing.");
    return;
  }

  // Add a listener for messages from the iframe
  window.addEventListener("message", event => {
    // Validate the message origin to ensure security
    if (event.origin !== siteLiveUrl) return;

    if (event.data === "expand") {
      // Expand the chat container
      chatContainer.style.width = "100%";
      chatContainer.style.height = "100%";
      chatContainer.style.maxWidth = "470px";
      chatContainer.style.maxHeight = "800px";
    } else if (event.data === "collapse") {
      // Collapse the chat container to the initial button size
      chatContainer.style.width = "70px";
      chatContainer.style.height = "70px";
    }
  });
})();
